/**
 * This file is auto generated, see readme for details
 */

export const Icons: Record<string, string> = {
  account_balance: '\ue84f',
  add: '\ue145',
  add_box: '\ue146',
  arrow_back: '\ue5c4',
  arrow_right_alt: '\ue941',
  arrow_split: '\uea04',
  automation: '\uf421',
  badge: '\uea67',
  block: '\uf08c',
  cake: '\ue7e9',
  candlestick_chart: '\uead4',
  card_membership: '\ue8f7',
  check: '\ue5ca',
  check_small: '\uf88b',
  close: '\ue5cd',
  content_copy: '\ue14d',
  delete: '\ue92e',
  diamond: '\uead5',
  drag_handle: '\ue25d',
  east: '\uf1df',
  error: '\uf8b6',
  expand_less: '\ue5ce',
  expand_more: '\ue5cf',
  face: '\uf008',
  featured_seasonal_and_gifts: '\uef91',
  handyman: '\uf10b',
  home: '\ue9b2',
  info: '\ue88e',
  keyboard_arrow_left: '\ue314',
  keyboard_arrow_right: '\ue315',
  lightbulb: '\ue90f',
  local_library: '\ue54b',
  local_mall: '\ue54c',
  logout: '\ue9ba',
  magic_exchange: '\uf7f4',
  menu: '\ue5d2',
  menu_open: '\ue9bd',
  more_vert: '\ue5d4',
  navigate_next: '\ue5cc',
  newspaper: '\ueb81',
  open_in_new: '\ue89e',
  priority_high: '\ue645',
  read_more: '\uef6d',
  receipt: '\ue8b0',
  repeat: '\ue040',
  rocket_launch: '\ueb9b',
  savings: '\ue2eb',
  sell: '\uf05b',
  settings: '\ue8b8',
  share: '\ue80d',
  shuffle: '\ue043',
  smartphone: '\ue32c',
  support: '\uef73',
  sync_alt: '\uea18',
  trending_up: '\ue8e5',
  undo: '\ue166',
  unfold_more: '\ue5d7',
  vertical_align_top: '\ue25a',
  west: '\uf1e6'
}
