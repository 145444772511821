import { IconButton } from '@/Components/Buttons'
import { CardLink } from '@/Components/Card'
import { Popover, PopoverContent, PopoverTrigger } from '@/Components/Popover'
import { ButtonRouter } from '@/Components/Router'
import { cn } from '@/Lib'
import { HTMLAttributes, forwardRef } from 'react'

const InvestButtons = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    default_account?: string
  }
>(({ className, default_account, ...props }, ref) => {
  const params = default_account ? { default_account } : undefined
  return (
    <div
      ref={ref}
      className={cn('flex w-full items-center gap-x-2', className)}
      {...props}
    >
      <ButtonRouter href={route('move-money.index')} className='px-4'>
        Add money
      </ButtonRouter>
      <ButtonRouter
        href={route('invest.index', params)}
        className='bg-slate-200 px-4 text-medium hover:bg-slate-300'
      >
        Invest
      </ButtonRouter>
      <Popover>
        <PopoverTrigger asChild>
          <IconButton className='mx-0 rounded-full bg-slate-200 hover:bg-slate-300'>
            more_vert
          </IconButton>
        </PopoverTrigger>
        <PopoverContent align='end' className='flex flex-col gap-0'>
          <CardLink href={route('transfer.index', params)} className='min-w-40'>
            Transfer
          </CardLink>
          <CardLink href={route('withdraw.index', params)} className='min-w-40'>
            Withdraw
          </CardLink>
        </PopoverContent>
      </Popover>
    </div>
  )
})
InvestButtons.displayName = 'InvestButtons'

export { InvestButtons }
